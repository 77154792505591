<template>
  <div class="admin dashboard">

    <header class="admin-header cf">
      <h1>{{ $t('admin.dashboard') }}</h1>
      <div class="meta">
        <a @click="get_data" class="button button-red">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrows-rotate"
            class="svg-inline--fa fa-arrows-rotate" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor"
              d="M464 16c-17.67 0-32 14.31-32 32v74.09C392.1 66.52 327.4 32 256 32C161.5 32 78.59 92.34 49.58 182.2c-5.438 16.81 3.797 34.88 20.61 40.28c16.89 5.5 34.88-3.812 40.3-20.59C130.9 138.5 189.4 96 256 96c50.5 0 96.26 24.55 124.4 64H336c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32V48C496 30.31 481.7 16 464 16zM441.8 289.6c-16.92-5.438-34.88 3.812-40.3 20.59C381.1 373.5 322.6 416 256 416c-50.5 0-96.25-24.55-124.4-64H176c17.67 0 32-14.31 32-32s-14.33-32-32-32h-128c-17.67 0-32 14.31-32 32v144c0 17.69 14.33 32 32 32s32-14.31 32-32v-74.09C119.9 445.5 184.6 480 255.1 480c94.45 0 177.4-60.34 206.4-150.2C467.9 313 458.6 294.1 441.8 289.6z">
            </path>
          </svg>
          <span>{{ $t('admin._dashboard.reload') }}</span>
        </a>
      </div>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>

      <div class="row row-gutter-20">
        <div class="col-16">
          <div class="content-wrap">
            <h3>{{ $t('admin._dashboard.current_appointments') }}</h3>

            <div v-if="appointment_present" class="alert alert-info">
              {{ $t('admin._dashboard.close_appointments') }}.
            </div>
            <div v-else>

              <p>{{ $t('admin._dashboard.following_appointments') }}:</p>

              <div v-if="all_appointments && all_appointments.length == 0" class="alert alert-info">
                {{ $t('admin._dashboard.no_appointments') }}
              </div>
              <div v-else
                style="background:rgba(156,33,40,0.08);padding:20px;border-radius:10px;border:1px solid rgba(156,33,40,0.2)">
                <p style="margin:0"><a @click="fetch_appointment" class="button button-red button-100">{{
                  $t('admin._dashboard.choose_efficient_appointment') }} ›</a></p>

                <p style="margin: 30px 0 20px 0">{{
                  $t('admin._dashboard.select_appointment') }}:</p>

                <div v-for="appointment in all_appointments" v-bind:key="appointment.id">
                  <a @click="select_appointment(appointment)"
                    style="background:#fff;color:#444;font-weight:600;border:1px solid #f1f1f1;padding: 20px;display:block;box-shadow: 0px 3px 6px #0000000F;border-radius:5px;border: 1px solid #EAEAEA;">
                    {{ appointment.date_formatted }}, {{ appointment.time_formatted }} ({{ appointment.last_name }}, {{
                      appointment.first_name }})<br>
                    <span style="color:rgb(117, 117, 117);font-size:14px;font-weight:400;display:block;margin-top:6px">
                      <div v-for="service in appointment.services" v-bind:key="service.id" style="margin-bottom: 10px;">
                        <span class="category-name">{{ service.location_name }} / {{ service.category_name }}</span>
                        <span class="service-name"> / {{ service.service_name }} <small
                            v-if="service.service_amount && service.service_duration">({{ service.service_amount *
                              service.service_duration
                            }} min.)</small></span>
                      </div>
                      <span v-if="appointment.appointment_type == 'planned'">T{{ appointment.appointment_number }}
                        <small>(Insgesamt ca. {{ appointment.duration }} min.)</small></span>
                      <span v-if="appointment.appointment_type == 'spontaneous'">A{{ appointment.appointment_number }}
                        <small>(Insgesamt ca. {{ appointment.duration }} min.)</small></span>
                    </span>
                    <span style="color:#9C2128;display:block;margin-top:6px">Diesen Termin auswählen ›</span>
                  </a>
                </div>

              </div>

            </div>

            <div style="padding-top:20px;border-top: 2px dashed #f1f1f1;margin-top:40px">

              <p>Folgende Termine sind bereits aktiv:</p>

              <div v-if="appointments && appointments.length == 0" class="alert alert-info">
                Aktuell sind keine Termine bereits aktiv.
              </div>
              <div v-else style="background:#f1f1f1;padding:20px;border-radius:10px;border:1px solid rgb(213, 213, 213)">
                <div v-for="appointment in appointments" v-bind:key="appointment.id">
                  <router-link :to="'/admin/appointments/' + appointment.id"
                    style="background:#fff;color:#444;font-weight:600;border:1px solid #EAEAEA;padding: 20px;display:block;box-shadow: 0px 3px 6px #0000000F;border-radius:5px">
                    {{ appointment.date_formatted }}, {{ appointment.time_formatted }} ({{ appointment.last_name }}, {{
                      appointment.first_name }})
                    <span style="color:rgb(117, 117, 117);font-size:14px;font-weight:400;display:block;margin-top:6px">
                      <div v-for="service in appointment.services" v-bind:key="service.id" style="margin-bottom: 10px;">
                        <span class="category-name">{{ service.location_name }} / {{ service.category_name }}</span>
                        <span class="service-name"> / {{ service.service_name }} <small>({{ service.service_duration }}
                            min.)</small></span>
                      </div>
                      <span v-if="appointment.appointment_type == 'planned'">T{{ appointment.appointment_number }}</span>
                      <span v-if="appointment.appointment_type == 'spontaneous'">A{{ appointment.appointment_number
                      }}</span>
                      <span v-if="appointment.counter"> / An <strong>{{ appointment.counter.name }}</strong></span>
                    </span>
                    <span style="color:rgb(117, 117, 117);font-size:14px;font-weight:400;display:block;margin-top:6px">
                      <span style="display:inline-block;vertical-align:middle;margin-right:10px">Status:</span>
                      <span v-if="appointment.status == 'created'" class="status-created"
                        style="display:inline-block;vertical-align:middle">
                        <span class="status-icon" style="display:inline-block;vertical-align:middle"></span>
                        <span class="status-text" style="display:inline-block;vertical-align:middle">Reserviert</span>
                      </span>
                      <span v-if="appointment.status == 'checked_in'" class="status-checked_in"
                        style="display:inline-block;vertical-align:middle">
                        <span class="status-icon" style="display:inline-block;vertical-align:middle"></span>
                        <span class="status-text" style="display:inline-block;vertical-align:middle">Eingecheckt</span>
                      </span>
                      <span v-if="appointment.status == 'called'" class="status-checked_in"
                        style="display:inline-block;vertical-align:middle">
                        <span class="status-icon" style="display:inline-block;vertical-align:middle"></span>
                        <span class="status-text" style="display:inline-block;vertical-align:middle">Aufgerufen</span>
                      </span>
                      <span v-if="appointment.status == 'running'" class="status-running"
                        style="display:inline-block;vertical-align:middle">
                        <span class="status-icon" style="display:inline-block;vertical-align:middle"></span>
                        <span class="status-text" style="display:inline-block;vertical-align:middle">Läuft</span>
                      </span>
                      <span v-if="appointment.status == 'canceled'" class="status-canceled"
                        style="display:inline-block;vertical-align:middle">
                        <span class="status-icon" style="display:inline-block;vertical-align:middle"></span>
                        <span class="status-text" style="display:inline-block;vertical-align:middle">Storniert</span>
                      </span>
                      <span v-if="appointment.status == 'completed'" class="status-completed"
                        style="display:inline-block;vertical-align:middle">
                        <span class="status-icon" style="display:inline-block;vertical-align:middle"></span>
                        <span class="status-text" style="display:inline-block;vertical-align:middle">Fertig</span>
                      </span>
                      <span v-if="appointment.status == 'paused'" class="status-paused"
                        style="display:inline-block;vertical-align:middle">
                        <span class="status-icon" style="display:inline-block;vertical-align:middle"></span>
                        <span class="status-text" style="display:inline-block;vertical-align:middle">Pausiert</span>
                      </span>
                    </span>
                    <span style="color:#9C2128;display:block;margin-top:6px">Diesen Termin auswählen ›</span>
                  </router-link>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div class="col-8">
          <div class="content-wrap">
            <h3>{{ $t('admin._dashboard.counters') }}</h3>
            <p style="margin-bottom:0">
              <span style="display:block;margin-bottom:10px">Sie sind aktuell dem folgenden Schalter zugewiesen:</span>
              <span style="min-height:26px;display:block">
                <span v-if="user && user.counters && user.counters.length > 0"
                  style="display:inline-block;margin-right:10px;background: #F6F6F6;border: 1px solid #D0D0D0;border-radius: 4px;font-size:12px;padding:5px 8px">
                  {{ user.counters[0].name }}
                </span>
              </span>
            </p>
          </div>
          <div class="content-wrap">
            <h3>{{ $t('admin._dashboard.waiting_lines') }}</h3>
            <div v-for=" waiting_line in waiting_lines" v-bind:key="waiting_line.id" style="padding-bottom:20px">
              <span style="min-height:26px;display:block">
                <span
                  style="display:inline-block;margin-right:10px;background: #F6F6F6;border: 1px solid #D0D0D0;border-radius: 4px;font-size:12px;padding:5px 8px">
                  {{ waiting_line.name }}
                </span>
              </span>
              <p style="margin: 10px 0">
                <router-link :to="'/admin/waiting_lines/' + waiting_line.id" class="button button-red" style="width:100%">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="calendar"
                    class="svg-inline--fa fa-calendar" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path fill="currentColor"
                      d="M152 64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0C141.3 0 152 10.75 152 24V64zM48 448C48 456.8 55.16 464 64 464H384C392.8 464 400 456.8 400 448V192H48V448z">
                    </path>
                  </svg>
                  <span>Kalender öffnen</span>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'admin_dashboard',
  data() {
    return {
      loading: true,
      user: {},
      appointments: [],
      waiting_lines: [],
      data_interval: null,
      appointment_present: false,
      current_counter: null
    }
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API + '/user', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user = response.data.user;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user.id, { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user = response.data.user;
          this.current_counter = this.user.counters[0];
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/appointments', {
        params: {
          dashboard_all: true
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
        .then(response => {
          this.appointments = response.data.appointments;
          if (this.user.counters.length > 0) {
            this.appointments.forEach(appointment => {
              if (appointment.counter_id == this.current_counter.id) {
                if (appointment.status == "paused") {
                  // TODO?
                } else {
                  this.appointment_present = true;
                }
              }
            })
          }


        });
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/waiting_lines', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.waiting_lines = response.data.waiting_lines;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/appointments', {
        params: {
          dashboard: true
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
        .then(response => {
          this.all_appointments = response.data.appointments;
        });
      this.loading = false;
    },
    async fetch_appointment() {
      var appointment = null
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/appointments', {
        params: {
          dashboard: true
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
        .then(response => {
          if (response.data.appointments.length > 0) {
            appointment = response.data.appointments[0]
          }
        })
      if (appointment) {
        this.$http.patch(process.env.VUE_APP_BASE_API + '/admin/appointments/' + appointment.id, {
          counter_id: this.user.counters[0].id,
          user_id: this.user.id,
          status: 'called'
        }, {
          headers: { Authorization: this.$store.getters.get_token }
        })
          .then(response => {
            this.$notify({
              title: "Termin erfolgreich zugeordnet.",
              type: "success"
            });
            this.$router.push('/admin/appointments/' + response.data.appointment.id + '?user_id=' + this.user.id);
          })
      } else {
        this.$notify({
          title: "Aktuell befindet sich niemand in der Warteschlange.",
          type: "error"
        });
        this.get_data();
      }
    },
    select_appointment(appointment) {
      this.$http.patch(process.env.VUE_APP_BASE_API + '/admin/appointments/' + appointment.id, {
        counter_id: this.user.counters[0].id,
        user_id: this.user.id,
        status: 'called'
      }, {
        headers: { Authorization: this.$store.getters.get_token }
      })
        .then(response => {
          this.$notify({
            title: "Termin erfolgreich zugeordnet.",
            type: "success"
          });
          this.$router.push('/admin/appointments/' + response.data.appointment.id + '?user_id=' + this.user.id);
        })
    }
  },
  mounted() {
    this.get_data();
    var _this = this;
    this.data_interval = setInterval(function () {
      _this.get_data();
    }, 5000);
  },
  unmounted() {
    clearInterval(this.data_interval);
  },
}
</script>


<style lang="scss">
.dashboard {

  .status-created {
    .status-icon {
      background: #1A1D1C80;
    }

    .status-text {
      color: #1A1D1C80;
    }
  }

  .status-checked_in {
    .status-icon {
      background: #1A1D1C;
    }

    .status-text {
      color: #1A1D1C;
    }
  }

  .status-called {
    .status-icon {
      background: #000882;
    }

    .status-text {
      color: #000882;
    }
  }

  .status-running {
    .status-icon {
      background: #FFB40A;
    }

    .status-text {
      color: #FFB40A;
    }
  }

  .status-canceled {
    .status-icon {
      background: #CC0000;
    }

    .status-text {
      color: #CC0000;
    }
  }

  .status-completed {
    .status-icon {
      background: #07B400;
    }

    .status-text {
      color: #07B400;
    }
  }

  .status-paused {
    .status-icon {
      background: #CC0000;
    }

    .status-text {
      color: #CC0000;
    }
  }

  .status-hidden {
    .status-icon {
      background: #1A1D1C80;
    }

    .status-text {
      color: #1A1D1C80;
    }
  }

  .status-visible {
    .status-icon {
      background: #07B400;
    }

    .status-text {
      color: #07B400;
    }
  }
}
</style>
